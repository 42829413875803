define('m14a/components/tex-t', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var x3dText = Ember['default'].Component.extend({
    tagName: 'transform',
    layoutName: 'tex-t',
    attributeBindings: ['translation', 'rotation', 'bboxcenter', 'bboxsize', 'center', 'scale', 'scaleorientation'],
    classNames: ['testClassNameXXX'],
    bboxcenter: "0 0 0",
    bboxsize: "-1,-1,-1",
    center: "0 0 0",
    scale: "1,1,1",
    scaleorientation: "0,0,0,0",

    austrittspunktRechts: false,

    translation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var typ = this.get('typ');

      var x = 0;
      var y = 0;
      var z = 0;

      var cords;
      // var skalierungsfaktor = x3d.get('skalierungsfaktor');
      var skalierungsfaktor = 1;

      var item = x3d.get('bauteile').findBy('id', name);

      var zVerschiebung = 0.05 + Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z')) / 20;

      var hoeheMasskette = 0.5;
      var abstandVomBauteil = 0.15;
      var abstandVonDerMasslinie = 0.05;
      var hoeheLastenpfeil = bauteile.findBy('id', 'gleichlast1').get('boxsizes').objectAt(0).get('y') + abstandVomBauteil;

      switch (typ) {
        case 'breite':
          z = 0;

          if (name === 'material1') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20) - bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 10 - x3d.get('laengeFehlstelle') / 10;
              y = -(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10);
              x = x - abstandVomBauteil - hoeheMasskette - abstandVonDerMasslinie;
            } else {
              x = -(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20) - bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x') / 10 - x3d.get('laengeFehlstelle') / 10;
              y = -(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) - abstandVomBauteil - hoeheMasskette + abstandVonDerMasslinie;
            }
          } else if (name === 'material23') {

            if (Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) > Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y'))) {
              y = bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10;
            } else {
              y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
            }

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 0.45 + Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x')) / 20 + Number(bauteile.findBy('id', 'material12').get('boxsizes').objectAt(0).get('x')) / 10 + abstandVomBauteil + hoeheMasskette + abstandVonDerMasslinie;
              y = y;
            } else {
              x = 0.45 + Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x')) / 20 + Number(bauteile.findBy('id', 'material12').get('boxsizes').objectAt(0).get('x')) / 10;
              y = y + abstandVomBauteil + hoeheMasskette + abstandVonDerMasslinie;
            }
          } else if (name === 'lager1') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              z = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('z') / 20 + abstandVomBauteil + hoeheMasskette - abstandVonDerMasslinie;
            } else {
              y = -abstandVomBauteil - hoeheMasskette + abstandVonDerMasslinie;
              z = bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('z') / 20;
            }
          } else if (name === 'uProfil3') {

            if (Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) > Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y'))) {
              y = bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y') / 10;
            } else {
              y = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 10;
            }

            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 0.45 + Number(bauteile.findBy('id', 'material23').get('boxsizes').objectAt(0).get('x')) / 20 + Number(bauteile.findBy('id', 'material12').get('boxsizes').objectAt(0).get('x')) / 10 + abstandVomBauteil + hoeheMasskette + abstandVonDerMasslinie;
            } else {
              x = 0.45 + Number(bauteile.findBy('id', 'material23').get('boxsizes').objectAt(0).get('x')) / 20 + Number(bauteile.findBy('id', 'material12').get('boxsizes').objectAt(0).get('x')) / 10;
              y = y + abstandVomBauteil + hoeheMasskette + abstandVonDerMasslinie;
            }
          }

          break;
        case 'hoehe':
          if (name === 'material12') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              y = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20);
              z = zVerschiebung + abstandVomBauteil + hoeheMasskette + abstandVonDerMasslinie + Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z')) / 10;
            } else {
              x = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20) + abstandVomBauteil + hoeheMasskette + abstandVonDerMasslinie;
              y = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20);
              z = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);
            }
          } else if (name === 'material23') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              y = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20);
              z = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20) - abstandVomBauteil - hoeheMasskette - abstandVonDerMasslinie;
            } else {
              x = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20) - abstandVomBauteil - hoeheMasskette - abstandVonDerMasslinie;
              y = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20);
              z = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);
            }
          } else if (name === 'uProfil3') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20);
              y = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20);
              z = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20) - abstandVomBauteil - hoeheMasskette - abstandVonDerMasslinie;
            } else {
              x = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20) - abstandVomBauteil - hoeheMasskette - abstandVonDerMasslinie;
              y = Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('y') / 20);
              z = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);
            }
          }

          break;
        case 'laenge':

          var material = '';

          if (Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) > Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y'))) {
            material = 'material1';
          } else {
            material = 'material2';
          }

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 0;
            y = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + Number(bauteile.findBy('id', material).get('boxsizes').objectAt(0).get('y') / 10);
            z = -zVerschiebung - abstandVomBauteil - hoeheMasskette - abstandVonDerMasslinie - Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z') / 10);
          } else {
            x = 0;
            y = hoeheLastenpfeil + abstandVomBauteil + hoeheMasskette + abstandVonDerMasslinie + Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + Number(bauteile.findBy('id', material).get('boxsizes').objectAt(0).get('y') / 10);
            z = -Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z') / 20);
          }
          break;
        case 'AbstandLager':

          var material = '';

          if (Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) > Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y'))) {
            material = 'material1';
          } else {
            material = 'material2';
          }

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20 + Number(x3d.get('AbstandLager')) / 20;
            y = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + Number(bauteile.findBy('id', material).get('boxsizes').objectAt(0).get('y') / 10);
            z = -2 * abstandVomBauteil - 2 * hoeheMasskette - abstandVonDerMasslinie - zVerschiebung - Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z') / 10);
          } else {
            x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20 + Number(x3d.get('AbstandLager')) / 20;
            y = 3 * abstandVomBauteil + 3 * hoeheMasskette + abstandVonDerMasslinie + Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + Number(bauteile.findBy('id', material).get('boxsizes').objectAt(0).get('y') / 10);
            z = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);
          }
          break;
        case 'AbstandVM':

          var material = '';

          if (Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) > Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y'))) {
            material = 'material1';
          } else {
            material = 'material2';
          }

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20 + Number(x3d.get('AbstandLager')) / 10 + Number(x3d.get('AbstandVM')) / 20;
            y = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + Number(bauteile.findBy('id', material).get('boxsizes').objectAt(0).get('y') / 10);
            z = -2 * abstandVomBauteil - 2 * hoeheMasskette - abstandVonDerMasslinie - zVerschiebung - Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z') / 10);
          } else {
            x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20 + Number(x3d.get('AbstandLager')) / 10 + Number(x3d.get('AbstandVM')) / 20;
            y = +3 * abstandVomBauteil + 3 * hoeheMasskette + abstandVonDerMasslinie + Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) + Number(bauteile.findBy('id', material).get('boxsizes').objectAt(0).get('y') / 10);
            z = -Number(bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('z') / 20);
          }
          break;
        case 'vWinkel':

          var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;

          x = Number(bauteile.findBy('id', 'hauptTraeger1').get('boxsizes').objectAt(0).get('x')) / 20 + 4;
          var yVerschiebung = x * Math.sin(vwinkel / 2);
          x = x * Math.cos(vwinkel / 2);
          y = yVerschiebung;
          z = 0;

          break;
      }

      if (typ === 'ueberkopplungslaenge' && (name === 'uProfil1' || name === 'material2')) {
        x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20 - Number(x3d.get('ueberkopplungslaenge')) / 20;

        if (x3d.get('stahlVerstaerkung') === true && x3d.get('istProfilstahl') === true) {
          z = bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('z') / 20;
        } else if (x3d.get('zweiseitigeVerstaerkung') === true) {
          z = bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z') / 20;
        }

        if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
          y = -(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10);
          z = z + abstandVomBauteil + hoeheMasskette - abstandVonDerMasslinie;
        } else {
          y = -(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) - abstandVomBauteil - hoeheMasskette + abstandVonDerMasslinie;
        }
      }

      if (typ === 'laengeFehlstelle' && name === 'material2') {
        x = bauteile.findBy('id', name).get('boxsizes').objectAt(0).get('x') / 20 - Number(x3d.get('ueberkopplungslaenge')) / 10 - Number(x3d.get('laengeFehlstelle')) / 20;

        if (x3d.get('stahlVerstaerkung') === true && x3d.get('istProfilstahl') === true) {
          z = bauteile.findBy('id', 'uProfil1').get('boxsizes').objectAt(0).get('z') / 20;
        } else if (x3d.get('zweiseitigeVerstaerkung') === true) {
          z = bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('z') / 20;
        }

        if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
          y = -(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10);
          z = z + abstandVomBauteil + hoeheMasskette - abstandVonDerMasslinie;
        } else {
          y = -(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y') / 10) - abstandVomBauteil - hoeheMasskette + abstandVonDerMasslinie;
        }
      }

      if (name.substring(0, 8) === 'schraube') {

        var xKopfPos = this.getSchraubeKopfPos(name, 'x');
        var zKopfPos = this.getSchraubeKopfPos(name, 'z');

        if (this.get('typ') === 'xPos') {
          if (x3d.get('istHt')) {
            z = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('z') / 20;
          } else {
            z = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('y') / 20;
          }

          if (this.austrittspunktRechts) {
            cords = bauteile.findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10 + 1 + 0.5 * skalierungsfaktor - 0.2 / skalierungsfaktor - 0.25 + ' ' + -xKopfPos / 2 + ' ' + (-z - 0.3);
          } else {
            if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
              cords = xKopfPos / 2 + ' ' + (this.postionDurchSkalierung() - 1) + ' ' + (-z - 0.3);
            } else {
              cords = xKopfPos / 2 + ' 0 ' + (-z - this.postionDurchSkalierung());
            }
          }
        }

        if (this.get('typ') === 'zPos') {

          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind') && !x3d.get('istHt')) {
            x = x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10 + 0.5;
          } else if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            x = x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10 + 0.3;
          } else {
            x = x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10;
          }

          var delta = 0;
          var zPos = 0;
          var bezugspunkt = 0;

          switch (this.get('typ')) {
            case 'randAbstand1':
              bezugspunkt = -x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;
              zPos = bezugspunkt + x3d.get('randAbstand1') / 20;
              delta = 0.6;
              break;
            case 'randAbstand2':
              bezugspunkt = x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;
              zPos = bezugspunkt - x3d.get('randAbstand2') / 20;
              break;
            case 'schraubenAbstand1':
              bezugspunkt = -x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;
              zPos = bezugspunkt + x3d.get('schraubenAbstand1') / 20 + x3d.get('randAbstand1') / 10;
              delta = 0.6;
              break;
            case 'schraubenAbstand2':
              bezugspunkt = x3d.get('bauteile').findBy('id', 'nebenTraeger').get('boxsizes').objectAt(0).get('z') / 20;
              zPos = bezugspunkt - x3d.get('schraubenAbstand2') / 20 - x3d.get('randAbstand2') / 10;
              break;
          }

          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            cords = x + ' ' + (this.postionDurchSkalierung(0) + delta - 0.35) + ' ' + zPos;
          } else {
            cords = x + delta + this.postionDurchSkalierung(0) + ' ' + y + ' ' + zPos;
          }
        }
      }

      if (name.substring(0, 11) === 'lastenPfeil') {
        y = -1.25 - (0.65 * skalierungsfaktor + 0.3 / skalierungsfaktor);
      }

      cords = x + ' ' + y + ' ' + z;
      return cords;
    }).property('model.firstObject.transformHelper', 'model.firstObject.vWinkel', 'model.firstObject.hWinkel', 'model.firstObject.istHt', 'model.firstObject.skalierungsfaktor', 'model.firstObject.schraubenGekreuzt', 'model.firstObject.schraubenlisteAktualisiert', 'model.firstObject.lastViewpoint'),

    rotation: (function () {
      var name = this.get('name');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var typ = this.get('typ');
      var values;
      var x = 0,
          y = 0,
          z = 0,
          omega = 0;
      var w90Grad = 90 * Math.PI / 180;
      var w240Grad = 240 * Math.PI / 180;
      var item = x3d.get('bauteile').findBy('id', name);

      switch (typ) {
        case 'breite':

          if (name === 'lager1') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = 0;
              z = 0;
              omega = -w90Grad;
            } else {
              x = 0;
              y = 0;
              z = 0;
              omega = 0;
            }
          } else if (name === 'material23' || name === 'uProfil3') {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = 1;
              z = 1;
              omega = w240Grad;
            } else {
              x = 0;
              y = 1;
              z = 0;
              omega = -w90Grad;
            }
          } else {
            if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = -1;
              z = -1;
              omega = w240Grad;
            } else {
              x = 0;
              y = 1;
              z = 0;
              omega = -w90Grad;
            }
          }
          break;
        case 'hoehe':

          if (name === 'material12') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = -1;
              y = 1;
              z = 1;
              omega = w240Grad;
            } else {
              x = 0;
              y = 0;
              z = 1;
              omega = -w90Grad;
            }
          } else if (name === 'material23' || name === 'uProfil3') {
            if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
              x = 1;
              y = 1;
              z = -1;
              omega = w240Grad;
            } else {
              x = 0;
              y = 0;
              z = 1;
              omega = w90Grad;
            }
          }

          break;
        case 'laenge':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          } else {
            x = 0;
            y = 0;
            z = 1;
            omega = 0;
          }
          break;
        case 'AbstandLager':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          } else {
            x = 0;
            y = 0;
            z = 1;
            omega = 0;
          }
          break;
        case 'AbstandVM':
          if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            x = 1;
            y = 0;
            z = 0;
            omega = -w90Grad;
          } else {
            x = 0;
            y = 0;
            z = 1;
            omega = 0;
          }
          break;
        case 'vWinkel':

          var vwinkel = Number(x3d.get('vWinkel')) * Math.PI / 180;
          x = 0;
          y = 0;
          z = 1;
          omega = -w90Grad + vwinkel / 2;

          break;
      }

      if ((typ === 'ueberkopplungslaenge' || typ === 'laengeFehlstelle') && name === 'material2') {
        if (bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
          x = 1;
          y = 0;
          z = 0;
          omega = -w90Grad;
        } else {
          x = 0;
          y = 0;
          z = 0;
          omega = 0;
        }
      }

      values = x + ' ' + y + ' ' + z + ' ' + omega;

      if (name.substring(0, 8) === 'schraube') {

        if (this.get('typ') === 'xPos') {
          if (this.austrittspunktRechts) {
            values = '0 0 1 -1.5707';
          } else {
            if (bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
              values = '0 0 0 0';
            } else {
              values = '1 0 0 -1.5707';
            }
          }
        }

        if (this.get('typ') === 'zPos') {
          if (bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            values = '0 1 0 -1.5707';
          } else {
            values = '1 1 1 4.2';
          }
        }
      }

      if (name.substring(0, 11) === 'lastenPfeil') {
        values = '0 0 0 0';
      }

      return values;
    }).property('model.firstObject.transformHelper', 'model.firstObject.istHt', 'model.firstObject.lastViewpoint'),

    wert: (function () {

      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);
      var bauteile = x3d.get('bauteile');
      var value = ' ';

      var item = x3d.get('bauteile').findBy('id', name);

      switch (typ) {
        case 'breite':
          if (name === 'material1' && x3d.get('htBreiteEingetragen') && !bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
            value = Number(item.get('boxsizes').objectAt(0).get('z')).toFixed(1);
          }
          if (name === 'material23' && x3d.get('breiteMaterial2Eingetragen') && !bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
            value = Number(item.get('boxsizes').objectAt(0).get('z')).toFixed(1);
          }
          if (name === 'lager1' && x3d.get('auflagerlaengeEingetragen') && !bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            value = Number(item.get('boxsizes').objectAt(0).get('x')).toFixed(1);
          }
          if (name === 'uProfil3') {
            value = Number(item.get('boxsizes').objectAt(0).get('z')).toFixed(1);
          }
          break;
        case 'hoehe':
          if (name === 'material12' && x3d.get('htHoeheEingetragen') && !bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          }
          if (name === 'material23' && x3d.get('hoeheMaterial2Eingetragen') && !bauteile.findBy('id', 'viewpointOben').get('cameraProperties').objectAt(0).get('setBind')) {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          }
          if (name === 'uProfil3') {
            value = Number(item.get('boxsizes').objectAt(0).get('y')).toFixed(1);
          }
          break;
        case 'laenge':
          if (name === 'material1' && x3d.get('systemlaengeEingetragen') && !bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            value = Number(x3d.get('systemlaenge')).toFixed(1);
          }
          break;
        case 'AbstandLager':
          if (name === 'lager1' && typ === 'AbstandLager' && !bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            value = Number(x3d.get('AbstandLager')).toFixed(1);
          }
          break;
        case 'AbstandVM':
          if (name === 'lager1' && typ === 'AbstandVM' && !bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            value = Number(x3d.get('AbstandVM')).toFixed(1);
          }
          break;
        case 'vWinkel':
          if (x3d.get('vWinkelEingetragen')) {
            value = Number(x3d.get('vWinkel')).toFixed(1);
          }
          break;
        case 'ueberkopplungslaenge':
          if (x3d.get('ueberkopplungslaengeEingetragen') && !bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            value = Number(x3d.get('ueberkopplungslaenge')).toFixed(1);
          }
          break;
        case 'laengeFehlstelle':
          if (x3d.get('laengeFehlstelleEingetragen') && !bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
            value = Number(x3d.get('laengeFehlstelle')).toFixed(1);
          }
          break;
      }

      if (name.substring(0, 8) === 'schraube' && x3d.get('ergebnisGeladen')) {
        if (this.get('typ') === 'xPos' && !bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind')) {
          var xKopfPos = this.getSchraubeKopfPos(name, 'x');
          value = Math.abs((xKopfPos * 10).toFixed(1));
          value = parseFloat(value).toFixed(1);
        }

        if (this.get('typ') === 'zPos' && !bauteile.findBy('id', 'viewpointFront').get('cameraProperties').objectAt(0).get('setBind')) {
          switch (this.get('typ')) {
            case 'randAbstand1':
              value = x3d.get('randAbstand1');
              value = parseFloat(value).toFixed(1);
              break;
            case 'randAbstand2':
              value = x3d.get('randAbstand2');
              value = parseFloat(value).toFixed(1);
              break;
            case 'schraubenAbstand1':
              value = x3d.get('schraubenAbstand1');
              value = parseFloat(value).toFixed(1);
              break;
            case 'schraubenAbstand2':
              value = x3d.get('schraubenAbstand2');
              value = parseFloat(value).toFixed(1);
              break;
          }
        }
      }

      if (name === 'lastenPfeil1') {
        value = x3d.get('querkraft');
        value = parseFloat(value).toFixed(1) + ' kN';
      }

      if (name === 'momentlast') {
        value = x3d.get('momentlast');
        value = parseFloat(value).toFixed(1) + ' kN';
      }

      return value;
    }).property('model.firstObject.transformHelper', 'model.firstObject.schraubenlisteAktualisiert'),

    diffusecolor: (function () {
      var name = this.get('name');
      var typ = this.get('typ');
      var x3d = this.get('model').objectAt(0);

      var emsvcolor = '0 0 0';

      if (x3d.get('htbreiteAktiv') === true && name === 'material1' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hthoeheAktiv') === true && name === 'material12' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('breiteMaterial2Aktiv') === true && name === 'material23' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('hoeheMaterial2Aktiv') === true && name === 'material23' && typ === 'hoehe') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('systemlaengeAktiv') === true && name === 'material1' && typ === 'laenge') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('vWinkelAktiv') === true && typ === 'vWinkel') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('ueberkopplungslaengeAktiv') === true && name === 'material2' && typ === 'ueberkopplungslaenge') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('laengeFehlstelleAktiv') === true && name === 'material2' && typ === 'laengeFehlstelle') {
        emsvcolor = '1 0 0';
      }

      if (x3d.get('auflagerlaengeAktiv') === true && name === 'lager1' && typ === 'breite') {
        emsvcolor = '1 0 0';
      }

      return emsvcolor;
    }).property('model.firstObject.htbreiteAktiv', 'model.firstObject.hthoeheAktiv', 'model.firstObject.breiteMaterial2Aktiv', 'model.firstObject.hoeheMaterial2Aktiv', 'model.firstObject.systemlaengeAktiv', 'model.firstObject.vWinkelAktiv', 'model.firstObject.laengeFehlstelleAktiv', 'model.firstObject.ueberkopplungslaengeAktiv', 'model.firstObject.auflagerlaengeAktiv'),

    textgroesse: (function () {
      var skalierungsfaktor = this.get('model').objectAt(0).get('skalierungsfaktor');
      return 0.3 * skalierungsfaktor;
    }).property('model.firstObject.skalierungsfaktor', 'model.firstObject.transformHelper'),

    getSchraubeKopfPos: function getSchraubeKopfPos(name, achse) {
      var value = 0;

      var x3d = this.get('model').objectAt(0);

      var item = x3d.get('bauteile').findBy('id', name);

      var xVerschiebung = item.get('translations').objectAt(0).get('x') / 10;
      var yVerschiebung = item.get('translations').objectAt(0).get('y') / 10;
      var zVerschiebung = item.get('translations').objectAt(0).get('z') / 10;

      var schraubenlaenge = item.get('screwProperties').objectAt(0).get('length') / 10;
      var alpha = item.get('rotations').objectAt(0).get('z');
      var beta = item.get('rotations').objectAt(0).get('y');

      if (alpha > 1.5707 && !x3d.get('schraubenGekreuzt')) {
        alpha = 1.5707 - (alpha - 1.5707);
        xVerschiebung = xVerschiebung * -1;
      }

      var delta = item.get('screwProperties').objectAt(0).get('headdiameter') / 20 * Math.cos(alpha);

      // var xKopfPos = -(Math.cos(beta)*yVerschiebung/Math.tan(alpha) - xVerschiebung);

      var xKopfPos;

      if (xVerschiebung > 0) {

        var vwinkel = x3d.get('vWinkel');
        var xGesamt = yVerschiebung / Math.tan(alpha - 1.5707);
        var x2 = Math.abs(xGesamt) - xVerschiebung / Math.cos(beta);
        var gamma = Math.PI - (alpha - 1.5707) - vwinkel * Math.PI / 180;
        var a = x2 * Math.sin(alpha - 1.5707) / Math.sin(gamma);
        xKopfPos = Math.cos(vwinkel * Math.PI / 180) * a * -1;
        xKopfPos = Math.cos(beta) * xKopfPos;
      } else {
        xKopfPos = -(Math.cos(beta) * yVerschiebung / Math.tan(alpha) - xVerschiebung);
        if (Number(xKopfPos) > Number(x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10)) {
          this.austrittspunktRechts = true;
          xKopfPos = (xKopfPos - x3d.get('bauteile').findBy('id', 'hauptTraeger').get('boxsizes').objectAt(0).get('x') / 10) * Math.sin(alpha);
        }
      }

      var zKopfPos = Math.sin(beta) * (Math.cos(alpha) * schraubenlaenge) - zVerschiebung;

      if (achse === 'x') {
        value = xKopfPos;
      } else if (achse === 'z') {
        value = zKopfPos;
      }

      return value;
    },

    postionDurchSkalierung: function postionDurchSkalierung(richtung) {
      var x3d = this.get('model').objectAt(0);
      var skalierungsfaktor = x3d.get('skalierungsfaktor') + 0.6;
      // var value = ((0.6*skalierungsfaktor) + richtung*(0.14/skalierungsfaktor));
      var value = 0.2 * skalierungsfaktor;
      return 0;
    }

  });

  exports['default'] = x3dText;

});