define('m14a/controllers/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  Ember['default'].deprecate = function () {};

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var inject = Ember['default'].inject;

  exports['default'] = Ember['default'].Controller.extend({

    version: "##version##",

    loggedIn: true,

    wuerth: true,
    swg: false,

    environment: "m14a", // "offline" = offline Version "m14a" = default würth,  "m14adev" = würth dev, "m14aswg" = default swg,  "m14aswgdev" = swg dev

    initialized: false,
    calculationid: "",
    debug: true,
    xml: false,
    projektdatenInvalid: false,
    geometrieInvalid: true,
    verstaerkungInvalid: true,
    verbundstellenInvalid: false,
    lasteinwirkungInvalid: true,
    verbindungsmittelInvalid: true,
    schraubenausrichtungInvalid: false,
    schraubenInfo: "",
    schraubenCAD: "",
    produktkatalog: "https://www.onlinebemessung.de/webapps/webapp_wuerth/produktinfo/de/Catalog.pdf",
    eta: "https://www.onlinebemessung.de/webapps/webapp_wuerth/produktinfo/de/ETA-110190.pdf",
    unsaved: true,

    server: "",
    pfad: "",

    i18n: inject.service(),
    current: computed.readOnly('i18n.locale'),

    bemessungsvorschrift: "EC5 (DE)",

    username: '',
    sessionid: '',

    si: "DE",
    so: "DE",
    kl: "DE",
    kat: "DE",
    lc: "EC 1 NAD (DE)",
    ln: "DE",
    tc: "EC 5 NAD (DE)",
    tn: "DE",

    languages: {
      "DE": "deutsch",
      "DK": "dansk",
      "EN": "englisch",
      "FR": "franzoesisch",
      "IT": "italienisch",
      "NL": "nederlands",
      "PL": "polnisch",
      "ES": "espanol",
      "FI": "suomi",
      "SE": "schwedisch"
    },

    countries: {
      "CL": "chile",
      "DK": "daenemark",
      "DE": "deutschland",
      "EN": "england",
      "FI": "finnland",
      "FR": "frankreich",
      "NL": "niederlande",
      "IT": "italien",
      "LU": "luxemburg",
      "HR": "kroatien",
      "NO": "norwegen",
      "AT": "oesterreich",
      "PL": "polen",
      "RO": "rumaenien",
      "SE": "schweden",
      "CH": "schweiz",
      "SK": "slowakei",
      "SI": "slowenien",
      "ES": "spanien",
      "CZ": "tschechien",
      "HU": "ungarn"
    },

    selectedLanguage: "deutsch",
    selectedCountry: "deutschland",

    wuerthlogo: true,

    init: function init() {
      var self = this;
      this._super();

      console.log(" ");
      console.log(window.location.host);
      console.log(window.location.host.search('localhost'));
      console.log(" ");

      var username = self.getParameterByName('user_name');
      var sessionid = self.getParameterByName('user_sessionid');

      self.setCountryVars();

      self.set('username', username);
      self.set('sessionid', sessionid);

      var environment = externalConfig.environments[self.get('environment')];
      var url = externalConfig[environment].uriAuthentifizierung;

      $.ajax({
        type: "POST",
        //url: "https://127.0.0.1:8000/Nutzerverwaltung/authentifiziereSession/",
        url: url,
        data: {
          username: username,
          sessionid: sessionid
        }
      }).done(function (res) {
        var authenticationResult = res.split(";");
        console.log(authenticationResult);
        var auth = parseInt(authenticationResult[0]);
        var level = parseInt(authenticationResult[1]);
        console.log(authenticationResult);
        if (auth == -1) {
          console.log("login unsuccessful.");
        } else if (auth == 0) {
          self.set('loggedIn', true);
          if (level == 0) {
            self.set('bauderMaster', false);
          } else if (level >= 1) {
            self.set('bauderMaster', true);
          }
          var applicationdata = self.get('model').application.objectAt(0);
          applicationdata.set('userName', username);
          console.log("login successful!");
        }

        var applicationdata = self.get('model').application.objectAt(0);

        // applicationdata.set('userName', username);
        applicationdata.set('spracheinput', self.get('si'));
        self.send('setSpracheInput', self.get('si'));

        applicationdata.set('spracheoutput', self.get('so'));
        applicationdata.set('kennungland', self.get('kl'));
        applicationdata.set('katalog', self.get('kat'));
        applicationdata.set('loadsCode', self.get('lc'));
        applicationdata.set('loadsNA', self.get('ln'));
        applicationdata.set('timberCode', self.get('tc'));
        applicationdata.set('timberNA', self.get('tn'));

        self.set('selectedCountry', self.get('i18n').t(self.countries[self.get('kl')]));

        // var applicationdataJSON = JSON.stringify(applicationdata);
        //
        // var projektdatendata = self.controllerFor('application').get('model').projektdaten.objectAt(0);
        // var projektdatendataJSON = JSON.stringify(projektdatendata);
        //
        // var supercontroller = self.controllerFor('supercontroller');
        // var JSONdata = supercontroller.uebergabedatenErzeugen();
        //
        var server = externalConfig[environment].server;
        var pfad = externalConfig[environment].pfad;

        // $.ajax({
        //   type: "POST",
        //   url: server + "m14a/DE/initialisiereGUI/" +self.controllerFor('supercontroller').erzeugeAufrufzusatz(false),
        //   data: {
        //   }
        // }).done(function(data, statusText, xhr) {

        self.controllerFor('supercontroller').logaufruf("initialisiereGUI", 200);

        //   console.log('initialisiereGUI data: ');
        //   console.log(data);
        //
        // })
      });

      self.store.createRecord('bauteile', {});
      self.store.createRecord('lasteinwirkung', {});
      self.store.createRecord('anschlussgeometrie', {});
      self.store.createRecord('projektdaten', {});

      self.transitionToRoute('projektdaten');

      // var environment = externalConfig.environments.m14a;

      var environment = externalConfig.environments[self.environment];

      console.log('self.environment: ' + self.environment);
      console.log('environment: ' + environment);

      self.set('server', externalConfig[environment].server);
      self.set('pfad', externalConfig[environment].pfad);

      self.get('i18n').addTranslations('cz', externalTranslations.cz);
      self.get('i18n').addTranslations('de', externalTranslations.de);
      self.get('i18n').addTranslations('dk', externalTranslations.dk);
      self.get('i18n').addTranslations('ee', externalTranslations.ee);
      self.get('i18n').addTranslations('en', externalTranslations.en);
      self.get('i18n').addTranslations('es', externalTranslations.es);
      self.get('i18n').addTranslations('fi', externalTranslations.fi);
      self.get('i18n').addTranslations('fr', externalTranslations.fr);
      self.get('i18n').addTranslations('gr', externalTranslations.gr);
      self.get('i18n').addTranslations('hr', externalTranslations.hr);
      self.get('i18n').addTranslations('hu', externalTranslations.hu);
      self.get('i18n').addTranslations('it', externalTranslations.it);
      self.get('i18n').addTranslations('lt', externalTranslations.lt);
      self.get('i18n').addTranslations('lv', externalTranslations.lv);
      self.get('i18n').addTranslations('nl', externalTranslations.nl);
      self.get('i18n').addTranslations('no', externalTranslations.no);
      self.get('i18n').addTranslations('pl', externalTranslations.pl);
      self.get('i18n').addTranslations('pt', externalTranslations.pt);
      self.get('i18n').addTranslations('ro', externalTranslations.ro);
      self.get('i18n').addTranslations('se', externalTranslations.se);
      self.get('i18n').addTranslations('si', externalTranslations.si);
      self.get('i18n').addTranslations('sk', externalTranslations.sk);
    },

    getParameterByName: function getParameterByName(name, url) {

      if (!url) {
        url = window.location.href;
      }
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    },

    setCountryVars: function setCountryVars() {

      var self = this;

      var si = self.getParameterByName('spracheinput');
      var so = self.getParameterByName('spracheoutput');
      var kl = self.getParameterByName('kennungland');
      var kat = self.getParameterByName('katalog');
      var lc = self.getParameterByName('loadsCode');
      var ln = self.getParameterByName('loadsNA');
      var tc = self.getParameterByName('timberCode');
      var tn = self.getParameterByName('timberNA');

      if (window.location.href.indexOf("timberCode") == -1) {
        self.set('si', "DE");
        self.set('so', "DE");
        self.set('kl', "DE");
        self.set('kat', "DE");
        self.set('lc', "EC1");
        self.set('ln', "DE");
        self.set('tc', "EC5");
        self.set('tn', "DE");
        self.set('bemessungsvorschrift', "EC5 (DE)");
      } else {
        self.set('si', si);
        self.set('so', so);
        self.set('kl', kl);
        self.set('kat', kat);
        self.set('lc', lc);
        self.set('ln', ln);
        self.set('tc', tc);
        self.set('tn', tn);

        if (tc.slice(10, tc.length - 1).length === 2) {
          self.set('bemessungsvorschrift', "EC5 (" + tc.slice(10, tc.length - 1) + ")");
        } else {
          if (tc.slice(10, tc.length - 1) === "D") {
            self.set('bemessungsvorschrift', "EC5 (DE)");
          } else if (tc.slice(10, tc.length - 1) === "A") {
            self.set('bemessungsvorschrift', "EC5 (AT)");
          } else if (tc.slice(10, tc.length - 1) === "I") {
            self.set('bemessungsvorschrift', "EC5 (IT)");
          } else if (tc.slice(10, tc.length - 1) === "F") {
            self.set('bemessungsvorschrift', "EC5 (FR)");
          }
        }
      }
    },

    resetApplication: function resetApplication() {
      var self = this;

      var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);
      applicationdata.set('treeLoaded', false);
      applicationdata.set('initialized', false);
      applicationdata.set('pdfErstellt', false);

      var application = self.controllerFor('application');
      application.set('verbindungsmittelInvalid', true);

      var downloadcenter = self.controllerFor('downloadcenter');
      downloadcenter.set('pdfErstellt', false);

      self.transitionToRoute('projektdaten');
    },

    actions: {

      setSpracheInput: function setSpracheInput(spracheInput) {
        var self = this;
        this.get('i18n').set('locale', spracheInput.toLowerCase());
        var applicationdata = this.get('model').application.objectAt(0);
        applicationdata.set('spracheinput', spracheInput);
        applicationdata.set('spracheoutput', spracheInput);
        self.send('setCountryCode', spracheInput);

        self.set('selectedLanguage', self.get('i18n').t(self.languages[spracheInput]));

        if (spracheInput == "DE") {
          self.set('hilfelink', 'https://timberconnect.de/hilfe/wuerth/html/de/m14a___balkenkopfsanierung.html?ms=AAAA&st=MA%3D%3D&sct=MTc5MQ%3D%3D&mw=MzAw#'); // Würth
        } else {
            self.set('hilfelink', 'https://timberconnect.de/hilfe/wuerth/html/en/m14a___beam_head_refurbishment.html?ms=AAAA&st=MA%3D%3D&sct=MTcyMQ%3D%3D&mw=MzAw#'); // Würth
          }

        this.controllerFor('impressum').set('selectedLanguage', spracheInput);

        this.controllerFor('geometrie').setSelectFieldsContent();
        this.controllerFor('verstaerkung').setSelectFieldsContent();
        this.controllerFor('verbundstellen').setSelectFieldsContent();
        this.controllerFor('lasteinwirkung').setSelectFieldsContent();

        this.controllerFor('geometrie').set("initTrigger", !this.controllerFor('geometrie').set("initTrigger"));
        this.controllerFor('verstaerkung').set("initTrigger", !this.controllerFor('geometrie').set("initTrigger"));

        self.resetApplication();
      },

      setCountryCode: function setCountryCode(spracheinput) {

        spracheinput = spracheinput === "EN" ? "UK" : spracheinput;

        var self = this;
        var applicationdata = this.get('model').application.objectAt(0);
        applicationdata.set('kennungland', spracheinput);

        var firm = applicationdata.get('firm');

        var projektdaten = this.controllerFor('projektdaten');

        if (spracheinput === "DE") {
          projektdaten.set('plzservice', true);
        } else {
          projektdaten.set('plzservice', false);
        }

        var downloadcenter = this.controllerFor('downloadcenter');

        var cC = spracheinput;
        var eta = "120196";

        if (typeof linksDB.firm.findBy('id', firm) === "undefined") {
          firm = "SWG";
        }

        console.log("linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', " + cC + " ) = ");
        console.log(linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC));
        console.log(" ");

        if (typeof linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC) === "undefined") {
          cC = "EN";
        }

        console.log("cC = " + cC);

        if (firm === "WUERTH") {
          eta = "110190";
        }

        var prodkaturl = "";
        var etaurl = "";

        if (self.get('environment') === "offline") {
          prodkaturl = linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC).links.findBy("id", "katalog").offline;
          etaurl = linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC).links.findBy("id", eta).offline;
        } else {
          prodkaturl = linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC).links.findBy("id", "katalog").file;
          etaurl = linksDB.firm.findBy('id', firm).produktinfos.land.findBy('countryCode', cC).links.findBy("id", eta).file;
        }

        this.set('eta', etaurl);
        this.set('produktkatalog', prodkaturl);
        downloadcenter.set('eta', etaurl);
        downloadcenter.set('produktkatalog', prodkaturl);

        self.set('selectedCountry', self.get('i18n').t(self.countries[spracheinput]));

        self.set('wuerthlogo', cC === "DE" ? true : false);

        self.resetApplication();
      },

      setETA: function setETA(eta) {
        var applicationdata = this.get('model').application.objectAt(0);

        var etaurl = "assets/public/docs/eta_" + eta.toLowerCase() + ".pdf";

        this.set('eta', etaurl);
      },

      setBemessungsvorschrift: function setBemessungsvorschrift(bemessungsvorschrift) {
        var self = this;
        var applicationdata = this.get('model').application.objectAt(0);

        if (bemessungsvorschrift === "DE" || bemessungsvorschrift === "AT") {
          // applicationdata.set('loadsCode', "EC 1 NAD (" + bemessungsvorschrift + ")");
          // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
          applicationdata.set('loadsNA', bemessungsvorschrift);
          applicationdata.set('timberNA', bemessungsvorschrift);
        } else {
          if (bemessungsvorschrift === "DK" || bemessungsvorschrift === "FI") {
            // applicationdata.set('loadsCode', "EC 1 NAD (DE)");
            // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
            applicationdata.set('loadsNA', "DE");
            applicationdata.set('timberNA', bemessungsvorschrift);
          } else {
            // applicationdata.set('loadsCode', "EC 1 NAD (DE)");
            if (bemessungsvorschrift === "IT") {
              // applicationdata.set('loadsCode', "EC 1 NAD (DE)");
              applicationdata.set('loadsNA', "DE");
              // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
              applicationdata.set('timberNA', bemessungsvorschrift);
            } else if (bemessungsvorschrift === "FR") {
              // applicationdata.set('loadsCode', "EC 1 NAD ("+ bemessungsvorschrift +")");
              applicationdata.set('loadsNA', bemessungsvorschrift);
              // applicationdata.set('timberCode', "EC 5 NAD (" + bemessungsvorschrift + ")");
              applicationdata.set('timberNA', bemessungsvorschrift);
            }
          }
        }

        applicationdata.set('loadsCode', "EC1");
        applicationdata.set('timberCode', "EC5");

        this.set('bemessungsvorschrift', "EC5 (" + bemessungsvorschrift + ")");

        self.resetApplication();
      },

      downloadcenter: function downloadcenter(spracheInput) {
        this.transitionToRoute('downloadcenter');
      },

      verbindungsmittel: function verbindungsmittel(event) {
        console.log('test');
        var lasteinwirkung = this.controllerFor('lasteinwirkung');
        var applicationdata = this.get('model').application.objectAt(0);

        if (!applicationdata.get('treeLoaded')) {
          console.log("jetzt müsste der Tree neu geladen werden.");
          lasteinwirkung.send('proceedInput');
        } else {
          console.log("jetzt müsste der Tree NICHT neu geladen werden.");
        }
      },

      ergebnis: function ergebnis(event) {
        console.log('test');
        var verbindungsmittel = this.controllerFor('verbindungsmittel');

        verbindungsmittel.send('proceedInput');
      },

      newAction: function newAction(event) {
        var self = this;

        var protocol = window.location.protocol;
        var host = window.location.host;

        var applicationdata = this.get('model').application.objectAt(0);
        var si = applicationdata.get('spracheinput');
        var so = applicationdata.get('spracheoutput');
        var kl = applicationdata.get('kennungland');
        var kat = applicationdata.get('katalog');
        var lc = applicationdata.get('loadsCode');
        var ln = applicationdata.get('loadsNA');
        var tc = applicationdata.get('timberCode');
        var tn = applicationdata.get('timberNA');
        var bv = self.get('bemessungsvorschrift');

        window.location.href = protocol + "//" + host + "/?user_name=" + self.get('username') + "&user_sessionid=" + self.get('sessionid') + "&spracheinput=" + si + "&spracheoutput=" + so + "&kennungland=" + kl + "&katalog=" + kat + "&loadsCode=" + lc + "&loadsNA=" + ln + "&timberCode=" + tc + "&timberNA=" + tn + "&bemessungsvorschrift=" + bv;
      },

      contactAction: function contactAction(event) {

        var self = this;

        self.transitionToRoute('contact');
      },

      produktkatalogAnzeigen: function produktkatalogAnzeigen(event) {

        var self = this;

        self.transitionToRoute('contact');
      },

      etaAnzeigen: function etaAnzeigen(event) {

        var self = this;

        self.transitionToRoute('contact');
      },

      setCameraActiv: function setCameraActiv(camera) {
        var lastCamera = this.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', this.get('model').x3ddefault.objectAt(0).get('lastViewpoint'));

        this.get('model').x3ddefault.objectAt(0).set('lastViewpoint', camera);

        var activeCamera = this.get('model').x3ddefault.objectAt(0).get('bauteile').findBy('id', camera);

        lastCamera.get('cameraProperties').objectAt(0).set('setBind', false);
        activeCamera.get('cameraProperties').objectAt(0).set('setBind', true);

        // resetViewpoint() zu finden in /bower_components/viewpointmanagement/vpmanagements.js
        // setzt die viewpoints zurück
        this.zentriertObjekt();

        // this.get('model').x3ddefault.objectAt(0).set('transformHelper', !this.get('model').x3ddefault.objectAt(0).get('transformHelper'));
      },

      setDrahtgittermodel: function setDrahtgittermodel() {
        this.get('model').x3ddefault.objectAt(0).set('istGitterModell', !this.get('model').x3ddefault.objectAt(0).get('istGitterModell'));
      },

      saveAction: function saveAction() {

        console.log('speichern()');

        var self = this;

        self.transitionToRoute('downloadcenter');
        var dc = self.controllerFor('downloadcenter');
        dc.set('savingInProgress', true);

        var application = self.controllerFor('application');

        var applicationdata = application.get('model').application.objectAt(0);
        var applicationdataJSON = JSON.stringify(applicationdata);

        var projektdatendata = application.get('model').projektdaten.objectAt(0);
        var projektdatendataJSON = JSON.stringify(projektdatendata);

        // var geometriedata = application.get('model').geometrie.objectAt(0);
        // var geometriedataJSON = JSON.stringify(geometriedata);

        // var verstaerkungdata = application.get('model').verstaerkung.objectAt(0);
        // var verstaerkungdataJSON = JSON.stringify(verstaerkungdata);

        // var verbundstellendata = application.get('model').verbundstellen.objectAt(0);
        // var verbundstellendataJSON = JSON.stringify(verbundstellendata);

        // var lasteinwirkungdata = application.get('model').lasteinwirkung.objectAt(0);
        // var lasteinwirkungdataJSON = JSON.stringify(lasteinwirkungdata);

        // var uebergabedaten = $.extend(true, JSON.parse(projektdatendataJSON), JSON.parse(geometriedataJSON), JSON.parse(verstaerkungdataJSON), JSON.parse(verbundstellendataJSON), JSON.parse(lasteinwirkungdataJSON));

        // var bestandPredrilled = false;
        // if (geometriedata.get('MatKeyTimberElementOld') == 12 || geometriedata.get('MatKeyTimberElementOld') == 30) {
        //   bestandPredrilled = true;
        // } else {
        //   bestandPredrilled = false;
        // }

        // var verstaerkungPredrilled = false;
        // if (verstaerkungdata.get('MatKeyTimberElementReI') == 12 || verstaerkungdata.get('MatKeyTimberElementReI') == 30) {
        //   verstaerkungPredrilled = true;
        // } else {
        //   verstaerkungPredrilled = false;
        // }

        // var timberelementold = {
        //   "Querschnittswerte": {
        //     "b": geometriedata.get('TimberElement1_b'),
        //     "h": geometriedata.get('TimberElement1_h'),
        //     // "t_Fastener": geometriedata.get('TimberElement1_h'),
        //     "QuerschnittsID": "1"
        //   },
        //   "vorgebohrt": bestandPredrilled
        // };

        // var timberelementrei = {
        //   "Querschnittswerte": {
        //     "b": verstaerkungdata.get('breite_ReI'),
        //     "h": verstaerkungdata.get('hoehe_ReI'),
        //     // "t_Fastener": verstaerkungdata.get('hoehe_ReI'),
        //     "QuerschnittsID": "1"
        //   },
        //   "vorgebohrt": verstaerkungPredrilled
        // };

        // var steelelement = {
        //   "Querschnittswerte": {
        //     "b": verstaerkungdata.get('profilBreite'),
        //     "h": verstaerkungdata.get('profilHoehe')
        //   },
        //   "vorgebohrt": false,
        //   "FlachStahl": verstaerkungdata.get('FlachStahl'),
        //   "ProfilName": verstaerkungdata.get('ProfilName'),
        //   "f_yk": verstaerkungdata.get('f_yk'),
        //   "Bezeichnung": verstaerkungdata.get('Bezeichnung')
        // };

        // var externalloads = [
        //   lasteinwirkungdata.get('staendigeEinwirkungen'), // ständige einwirkungen
        //   lasteinwirkungdata.get('verEinw1'), // ver. einw'). 1
        //   lasteinwirkungdata.get('verEinw2') // ver. einw. 2
        // ]

        // var standardloads = [
        //   lasteinwirkungdata.get('eigengewicht'), // vermutlich auswahl "einfeldträger"
        //   lasteinwirkungdata.get('kled1'), // kled menu 1
        //   lasteinwirkungdata.get('kled2') // kled menu 2
        // ]

        // uebergabedaten.TimberElementOld = timberelementold;
        // uebergabedaten.TimberElementReI = timberelementrei;
        // uebergabedaten.SteelElement = steelelement;
        // uebergabedaten.ExternalLoads = externalloads;
        // uebergabedaten.StandardLoadEffectsIndexList = standardloads;

        // var JSONdata = JSON.stringify(uebergabedaten);

        var supercontroller = self.controllerFor('supercontroller');
        var JSONdata = supercontroller.uebergabedatenErzeugen();

        var initialized = applicationdata.get('initialized');

        var parasToSave = JSON.parse(JSONdata);

        var dataToSave = JSON.stringify(auxiliary.save([applicationdata.data, projektdatendata.data, parasToSave]));

        // console.log(JSON.stringify(dataToSave));

        //var dingsbums = JSON.stringify(dataToSave);

        var server = self.get('server');
        var pfad = self.get('pfad');

        console.log('server');
        console.log(server + "m14a/DE/initialisiereBerechnung/");

        console.log('pfad');
        console.log(pfad);

        if (self.debug) {
          console.log('kennung');
          console.log(applicationdataJSON);
          console.log('projektdaten');
          console.log(projektdatendataJSON);
          console.log('paras');
          console.log(JSONdata);
          console.log('dataToSave');
          console.log(dataToSave);
        }

        if (!initialized) {

          $.ajax({
            type: "POST",
            url: server + "initialisiereBerechnung/",
            //url: "https://136.243.4.143/berechnungsservice/m06/DE/erzeugeSchraubenliste/",
            data: {
              kennung: applicationdataJSON,
              // projektdaten: projektdatendataJSON,
              paras: JSONdata,
              modul: "m14a",
              pfad: pfad
            }
          }).done(function (data, statusText, xhr) {

            self.controllerFor('supercontroller').logaufruf("initialisiereBerechnung", xhr.status);

            var cid = data;

            console.log('cid: ' + cid);

            applicationdata.set('Calculation_ID', cid);
            applicationdata.set('initialized', true);
            dc.set('cid', data);

            $.ajax({
              type: "POST",
              url: server + "speichereBerechnung/" + cid + "/",
              data: {
                // kennung: applicationdataJSON,
                // paras: JSONdata
                dataToSave: dataToSave
              }
            }).done(function (data, statusText, xhr) {

              self.controllerFor('supercontroller').logaufruf("speichereBerechnung", xhr.status);

              dc.set('saveFile', data);
              dc.set('savingInProgress', false);
              dc.set('saved', true);

              //console.log(data);
              //window.open(data);
              // datei öffnen, bzw. link anzeigen
            });
          });
        } else {

            var cid = applicationdata.get('Calculation_ID');

            $.ajax({
              type: "POST",
              url: server + "speichereBerechnung/" + cid + "/",
              data: {
                // kennung: applicationdataJSON,
                // paras: JSONdata
                dataToSave: dataToSave
              }
            }).done(function (data, statusText, xhr) {

              self.controllerFor('supercontroller').logaufruf("speichereBerechnung", xhr.status);

              dc.set('saveFile', data);
              dc.set('savingInProgress', false);
              dc.set('saved', true);

              //console.log(data);
              //window.open(data);
              // datei öffnen, bzw. link anzeigen
            });
          }
        self.set('unsaved', false);
      },

      /**
       * This action is called when a file has been loaded.
       *
       * @method ACTION: fileLoaded
       */
      fileLoaded: function fileLoaded(file) {

        var self = this;

        if (self.xml) {
          var test = self.parseXml(file.data);
          console.log('test');
          console.log(xml2json(test, ""));
        } else {
          var applicationdata = self.controllerFor('application').get('model').application.objectAt(0);

          var projektdaten = self.controllerFor('projektdaten');
          var geometrie = self.controllerFor('geometrie');
          var verstaerkung = self.controllerFor('verstaerkung');
          var verbundstellen = self.controllerFor('verbundstellen');
          var lasteinwirkung = self.controllerFor('lasteinwirkung');

          var values = JSON.parse(file.data);

          self.send('setSpracheInput', values.spracheinput);

          applicationdata.set('katalog', values.katalog);

          self.send('setBemessungsvorschrift', values.timberNA);

          applicationdata.set('kennungland', values.kennungland);
          applicationdata.set('userID', values.userID);
          applicationdata.set('userName', values.userName);

          projektdaten.setValues(JSON.parse(file.data));
          geometrie.setValues(JSON.parse(file.data));
          verstaerkung.setValues(JSON.parse(file.data));
          verbundstellen.setValues(JSON.parse(file.data));
          lasteinwirkung.setValues(JSON.parse(file.data));
        }
      },

      toggleForm: function toggleForm() {
        $('.clearfix').toggleClass('formZu');
        $('#formToggler').toggleClass('open');

        setTimeout(function () {
          var x3dGrafik = document.getElementById("threedee");
          var x3d = document.getElementById("x3d");
          var canvas = document.getElementsByTagName("canvas");
          var x3druntime = document.getElementById('x3d').runtime;

          // canvas[0].setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          x3d.setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          // canvas[0].setAttribute('hight', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
          // x3d.setAttribute('height', Number(x3druntime.getWidth()) * 3 / 5);
          x3d.setAttribute('height', Number($(window).height()) - 300);
        }, 400);
      }
    },

    miniertX3D: function miniertX3D() {
      $('.clearfix').removeClass('formZu');
      $('#formToggler').removeClass('open');

      setTimeout(function () {
        var x3dGrafik = document.getElementById("threedee");
        var x3d = document.getElementById("x3d");
        var canvas = document.getElementsByTagName("canvas");
        var x3druntime = document.getElementById('x3d').runtime;

        // canvas[0].setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
        x3d.setAttribute('width', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
        // canvas[0].setAttribute('hight', Number($(window).width()) - Number(x3dGrafik.offsetLeft) - 20);
        // x3d.setAttribute('height', Number(x3druntime.getWidth()) * 3 / 5);
        x3d.setAttribute('height', Number($(window).height()) - 300);
      }, 400);
    },

    parseXml: function parseXml(xml) {

      var dom = null;
      if (window.DOMParser) {
        try {
          dom = new DOMParser().parseFromString(xml, "text/xml");
        } catch (e) {
          dom = null;
        }
      } else if (window.ActiveXObject) {
        try {
          dom = new ActiveXObject('Microsoft.XMLDOM');
          dom.async = false;
          if (!dom.loadXML(xml)) // parse error ..

            window.alert(dom.parseError.reason + dom.parseError.srcText);
        } catch (e) {
          dom = null;
        }
      } else alert("cannot parse xml string!");
      return dom;
    },

    zentriertObjekt: function zentriertObjekt() {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var ueberstand = 2;

      // var x = (Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x')) + Number(x3d.get('laengeFehlstelle')) + Number(x3d.get('ueberkopplungslaenge')) + Number(bauteile.findBy('id', 'material12').get('boxsizes').objectAt(0).get('x'))) / 10 + 1.2 * skalierungsfaktor;
      var x = (Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x')) + Number(x3d.get('laengeFehlstelle')) + Number(x3d.get('ueberkopplungslaenge')) + Number(bauteile.findBy('id', 'material12').get('boxsizes').objectAt(0).get('x'))) / 10 + 2;
      var y = Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y')) / 10;

      if (Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) > Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y'))) {
        y = y + Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y')) / 10;
      } else {
        y = y + Number(bauteile.findBy('id', 'material2').get('boxsizes').objectAt(0).get('y')) / 10;
      }

      y = y + 0.6;
      var z = Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('z')) / 10 + Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('z')) / 10 + 0.6;
      var distanz = (Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('x')) + Number(x3d.get('laengeFehlstelle')) + Number(x3d.get('ueberkopplungslaenge')) + Number(bauteile.findBy('id', 'material12').get('boxsizes').objectAt(0).get('x'))) / 10 + 2 * 0.6;

      var arr = [x, y, z];
      arr.sort(function (a, b) {
        return b - a;
      });

      var d = 0;
      var val = Number(arr[0]);

      if (bauteile.findBy('id', 'viewpointDefault').get('cameraProperties').objectAt(0).get('setBind') === true) {
        d = Math.sqrt(Math.pow(val, 2) + Math.pow(val * 0.4, 2) + Math.pow(val, 2));
      } else {
        d = arr[0];
      }

      x3d.set('aktuelleDistance', d);

      resetViewpoint(d, y, z, distanz);
    },

    istProfilstahl: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var value = false;

      if (x3d.get('istProfilstahl') && x3d.get('stahlVerstaerkung')) {
        value = true;
      }
      return value;
    }).property('model.x3ddefault.firstObject.transformHelper'),

    zweiseitigeVerstaerkung: (function () {
      return this.get('model').x3ddefault.objectAt(0).get('zweiseitigeVerstaerkung');
    }).property('model.x3ddefault.firstObject.zweiseitigeVerstaerkung'),

    systemlaengeAnzeigen: (function () {
      var value = true;
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');

      if (x3d.get('abstaendeAnzeigen') === false || bauteile.findBy('id', 'viewpointLinks').get('cameraProperties').objectAt(0).get('setBind') === true) {
        value = false;
      }

      return value;
    }).property('model.x3ddefault.firstObject.abstaendeAnzeigen', 'model.x3ddefault.firstObject.lastViewpoint'),

    x3dTranslation: (function () {
      var x3d = this.get('model').x3ddefault.objectAt(0);
      var bauteile = x3d.get('bauteile');
      var y;

      y = -(Number(bauteile.findBy('id', 'lager1').get('boxsizes').objectAt(0).get('y')) + Number(bauteile.findBy('id', 'material1').get('boxsizes').objectAt(0).get('y'))) / 20;

      var translation = 2 + ' ' + y + ' ' + 0;

      return translation;
    }).property('model.x3ddefault.firstObject.transformHelper'),

    eingabenUngueltig: (function () {
      var self = this;
      var value = true;

      if (self.get('projektdatenInvalid') === false && self.get('geometrieInvalid') === false && self.get('verstaerkungInvalid') === false && self.get('verbundstellenInvalid') === false && self.get('lasteinwirkungInvalid') === false) {
        value = false;
      }

      return value;
    }).property('projektdatenInvalid', 'geometrieInvalid', 'verstaerkungInvalid', 'verbundstellenInvalid', 'lasteinwirkungInvalid')

  });

});